body {
  font-family: "Neucha", cursive;
  font-size: 1.5rem;
  line-height: 2.25rem;
}



@tailwind base;
@tailwind components;
@tailwind utilities;